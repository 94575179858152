export const privacypolicyPage = {
    title: `<span>Privacy Policy</span>`,
    description: `Care Casa is committed to protecting your privacy.`,
    googleReviewText: `Care Casa’s SDA home services have completely transformed my life. I have gained the independence and comfort I desire. I highly recommend them!`,
    contentSections: [
        {
            description: `
            Care Casa is committed to protecting your privacy and complying with the Privacy Act 1988 (Cth), the Australian Privacy Principles (APPs), and any relevant state or territory laws. This Privacy Policy outlines how we collect, use, store, and disclose your personal information. If you have any questions or concerns, please contact us at <a href="mailto:accounts@carecasa.com.au">accounts@carecasa.com.au</a> or call <a href="tel:+61874271805">(08) 7427 1805</a>`
        },
        {
            title: `1. Types of Information We Collect`,
            titleEC: `!mt-0`,
            description: `
            We only collect personal information necessary to provide our services, particularly in line with the National Disability Insurance Scheme (NDIS) policies. The types of personal information we may collect include:`,
            list: [
                `<b>Personal Details:</b> Name, address, phone number, and email.`,
                `<b>Health Information:</b> Details as per NDIS policies to provide Supported Disability Accommodation (SDA) services.`,
                `<b>NDIS Information:</b> Your NDIS number, service details, and other relevant data for SDA housing and services.`,
                `<b>Website Data:</b> Information collected via cookies and analytics tools when you visit our website.`
            ]
        },
        {
            title: `2. How We Collect Your Information`,
            description: `We gather personal information through various methods, including:`,
            list: [
                `Directly from you via forms on our website, phone calls, or in-person consultations.`,
                `From authorised third parties like support coordinators, guardians, or other NDIS-approved representatives.`,
            ]
        },
        {
            description: `We may also use cookies and tracking technologies on our website to understand user behaviour and improve our services.`,
        },
        {
            title: `3. Purpose of Collecting Information`,
            description: `We use your personal information for the following purposes:`,
            list: [
                `To provide and manage SDA housing services under the NDIS framework.`,
                `To respond to inquiries and requests related to our services.`,
                `To communicate updates, newsletters, and promotional content (with an option to opt out).`,
                `To improve and personalise your experience on our website.`,
            ]
        },
        {
            description: `<b>Note:</b> We do not use personal data for marketing purposes beyond the services we provide.`
        },
        {
            title: `4. Disclosure of Personal Information`,
            description: `We may share your personal information in the following circumstances:`,
            list: [
                `With NDIS-approved providers, support coordinators, and other entities necessary for delivering our services.`,
                `With government agencies, regulators, or other authorities if required by law.`,
                `With third-party service providers for IT support, cloud storage, and website analytics.`,
            ]
        },
        {
            description: `We take reasonable steps to ensure that any third party with whom we share your information complies with the Australian Privacy Principles.`
        },
        {
            title: `5. Data Security and Retention`,
            description: `We are committed to safeguarding your personal information. We use a combination of cloud-based systems and secure servers to protect your data from unauthorised access, misuse, or loss. We will only retain your information for as long as necessary to fulfil the purposes for which it was collected or as required by law. When no longer needed, we will securely delete or de-identify your data.`
        },
        {
            title: `6. Use of Cookies and Analytics`,
            description: `Our website uses cookies and similar technologies to enhance user experience and collect data on website usage. This may include tools like Google Analytics and Facebook Pixel to analyse trends and improve our online presence. You can adjust your browser settings to refuse cookies, though this may affect website functionality.`
        },
        {
            title: `7. Access to Your Information and Complaints`,
            description: `You have the right to access, update, or correct your personal information held by us. If you wish to make such a request or have a complaint about how we handle your data, please contact our designated privacy officer at <a href="mailto:accounts@carecasa.com.au">accounts@carecasa.com.au.</a>
            <br><br>We will respond to your request or complaint within a reasonable timeframe and in accordance with the Privacy Act.`
        },
        {
            title: `8. Overseas Data Disclosure`,
            description: `While we primarily store data within Australia, we may use overseas service providers (e.g., cloud storage) where necessary. We will ensure that any overseas transfer complies with the Australian Privacy Principles or equivalent data protection standards.`
        },
        {
            title: `9. Changes to This Privacy Policy`,
            description: `Care Casa reserves the right to amend this Privacy Policy at any time. Any updates will be posted on our website, so please review it periodically for changes.`
        },
        {
            title: `Contact Information`,
            description: ` For any privacy-related inquiries, please contact:
            <br>
            Email: <a href="mailto:accounts@carecasa.com.au">accounts@carecasa.com.au</a>
            <br>
            Phone: <a href="tel:+61874271805">(08) 7427 1805</a>`
        },
    ]
}